<template>
  <div class="user-center">
    <van-cell-group class="user-detail">
      <van-cell class="invoice-number">
        <view class="mid_menu">
          <van-row gutter="6">
            <van-col span="6">
              <van-image width="95%" src="/assets/head.png" />
            </van-col>
            <van-col span="18">
<!--              <van-col class="merchant-name">上海市闵行区江浩蔬菜经营部</van-col>-->
<!--              <van-col class="merchant-no">92310112MA1KUDMN4X</van-col>-->
              <van-col class="merchant-name">{{merchantInfo.merchant_name}}</van-col>
              <van-col class="merchant-no">{{merchantInfo.taxpayer_id_no}}</van-col>
            </van-col>
          </van-row>
        </view>
      </van-cell>
    </van-cell-group>
    <van-cell-group  class="font-number">
      <van-cell title="开票统计"   :value="merchantInfo.invoice_count"  is-link icon="/assets/sum_invoice.png" title-style="margin-left:0.2rem;font-size: 0.42rem;
font-weight: 400;" />
    </van-cell-group>
    <div style="height: 0.25rem"></div>
    <van-cell-group class="font-number">
      <van-cell title="修改密码" value=""  is-link icon="/assets/pwd.png" title-style="margin-left:0.2rem;font-size: 0.42rem;
font-weight: 400;" @click="showPopPass" />
    </van-cell-group>
    <van-cell class="logout-btn">
      <van-button style="border-radius: 4px;font-size: 18px;border: none;" block type="warning" class="logout-button-btn" @click="logout">
        退出登录
      </van-button>
    </van-cell>
  </div>

  <van-dialog
      v-model:show="showChangePass"
      title="修改密码"
      className="pass-dialog"
      :show-confirm-button="false"
      :close-on-click-overlay="true">
    <van-form @failed="onFailed" @submit="onSubmit">
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
          label="原始密码"
          v-model="password"
          type="password"
          name="password"
          placeholder="原始密码"
          :rules="[{ validator: validatorPass }]"
      />
      <!-- 通过 validator 进行函数校验 -->
      <van-field
          label="新密码"
          v-model="password1"
          name="password1"
          type="password"
          placeholder="新密码"
          :rules="[{ validator: validatorPass }]"
      />
      <!-- 通过 validator 返回错误提示 -->
      <van-field
          label="确认密码"
          v-model="password2"
          name="password2"
          type="password"
          placeholder="确认密码"
          :rules="[{ validator: CheckPass }]"
      />
      <div style="margin: 16px;">
        <van-button round block color="linear-gradient(to right, #ff6034, #ee0a24)" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </van-dialog>

</template>

<script>
import {toRefs,reactive,computed} from "vue";
import {useRouter} from "vue-router";
import {changePass} from "@/api/merchant";
import {Toast} from "vant";
import {useStore} from "vuex";

export default {
  name: "index",
  setup(){
    const route = useRouter();
    const store = useStore();
    const state = reactive({
        userInfo:{},
        showChangePass:false,
        password: '',
        password1: '',
        password2: '',
        merchantInfo:{}
    })

    const loadData = ()=>{

    }
    const showPopPass = ()=>{
        state.showChangePass = true;
    }
    // 退出登录
    const logout = () =>{
      localStorage.removeItem('user');
      localStorage.removeItem('user_token');
      localStorage.removeItem('open_id');
      route.push({
        path:'/login'
      })
    }
    console.info('setup...')

    // 校验函数可以直接返回一段错误提示
    const validatorPass = (val) =>{
      let check = /^[\w_-]{6,16}$/.test(val);
      if(!check){
        return "必须在6~16个字符之间"
      }
    }

    // 校验函数可以直接返回一段错误提示
    const CheckPass = (val) =>{
      let check = /^[\w_-]{6,16}$/.test(val);
      if(!check){
        return "必须在6~16个字符之间";
      }
      console.info(val);
      console.info(state.password1);
      if(val!==state.password1){
        return "两次密码输入不一致";
      }
    }

    const onFailed = (errorInfo) => {
      console.log('failed', errorInfo);
    };

    const onSubmit = (values) => {
      changePass(values).then((res)=>{
        if(res.Code===200){
          Toast.success({
            message:'密码修改成功'
          })
          setTimeout(function (){
            logout()
          },1000);
        }
        console.info(res)
      })
      console.log('submit', values);
    }
    // 获取用户信息
    state.merchantInfo = computed(() => store.state.merchantInfo)
    store.dispatch('loadMerchantInfo')
    return {
      ...toRefs(state),
      loadData,
      showPopPass,
      logout,
      onSubmit,
      onFailed,
      validatorPass,
      CheckPass
    }
  }
}
</script>

<style lang="less" scoped>
.user-detail{
  margin: 12px 0 0.25rem 0;
}
.user-center{
  height: auto;
  min-height: 100vh;
  //background-color: #fff;
  .user-photo{
    margin-bottom: 5vh;
    padding-top: 5vh;
  }
  .user-photo::after{
    border: unset;
  }
  :global(.user-photo .van-cell__value){
      text-align: center;
      margin: 0 auto;
      display: block;
  }
  :global(.van-cell__title){
    text-align: left;
  }
  .logout-btn{
    display: block;
    margin-bottom: 5vh;
    position: fixed;
    bottom: 10vh;
    background: none;
  }
  .merchant-name{
    height: 0.42667rem;
    font-size: 0.45333rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 0.37rem auto;
  }
  .merchant-no{
    height: 0.26667rem;
    font-size: 0.34667rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    margin: -0.1rem auto;
  }
  .invoice-number{
    //border: 1px solid #c1bebe;
  }
  .font-number .van-field__left-icon{
    margin-right: 3rem;
  }

  :deep(.logout-button-btn){
    margin: 0 auto;
    width: 60vw;
    background-color:#ff552e;
  }



  :deep(.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after){
    border: unset;
  }
}
</style>